import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../../components/layout'
import TwoColumn from "../../../components/two-column"
// import SingleContent from "../../../components/single-column"
import classNames from 'classnames'
import { developersContent } from "../../../lib/home-content"
import SEO from "../../../components/seo"

const ApiAndWebHooks = () => {
  const bannerContent = {
    title: "A clean, simple, modern and well documented API underpins Billsby.",
    description: [
      {
        text: "There's a lot you can do with Billsby without writing a single line of code. But if you want to do everything for yourself, our API lets you lift the hood and build your own interface for pretty much everything - frontend and backend."
      }
    ],
    isBanner: true,
    withPromotion: false,
    textColor: "#fff",
    backgroundColor: "#243f5c",
    // backgroundImage: require('../../../images/api-relay-race.svg'),
    image: require('../../../images/api-relay-race.svg'),
    svg: true,
    imageName: 'billsby banner image',
    // imagePosition: 'full-right',
    imagePosition: 'right',
    // sectionHomeBanner: true,
    sectionDevelopers: true,
    hasBg: false,
    buttons: [
      { 
        isExternal: true,
        url: 'https://app.billsby.com/registration',
        buttonText: 'Sign up today',
        buttonColor: 'orange'
      },
      {
        scheduleButton: true,
        buttonColor: 'black'
      }
    ]
  }

  const singleContent = {
    title: "If you can do it in Billsby, you can do it with our API",
    text: "That's our simple guiding principle - everything you see in our pre-built tools, from our checkout and account management to our control panel, is built with the same APIs you can use to build your own solutions. The only thing you can't build for yourself is the credit card field - this is to help you maintain PCI compliance - but you can use our lightweight card tokenizer instead.",
    button: [
      {
        buttonColor: "blue",
        url: "https://support.billsby.com/reference",
        btnText: "API specification",
      },
      {
        buttonColor: "blue",
        url: "https://support.billsby.com/docs",
        btnText: "Documentation",
      }
    ]
  }

  developersContent.backgroundColor = "#f3f5fb"
  developersContent.textColor= "#252422"
  developersContent.buttons[0].buttonColor = "black"

  const apiFirst = {
    title: "Don't tell the others, but we ship to the API first.",
    content: [
      {
        heading: "Some features are exclusive to the API",
        details: "Like the ability to make a one-time charge on a customers account, or programmatically add new products, plans and cycles.",
      },
      {
        heading: "API feature requests get priority",
        details: "Because they're generally quicker and easy to build, we can turn around custom API requests much quicker than full stack features.",
      }, 
      {
        heading: "We don't rate limit based on your plan",
        details: "We won't make you upgrade plans just to send API calls - we think those kinds of restrictions are old fashioned and unnecessary for revenue share based deals.",
      },  
    ],
    image: require('../../../images/api-ship-first.svg'),
    imageName: 'API Relay Race'
  }

  return (
    <Layout className="api-and-web-hooks">
      <SEO 
        title="API and web hooks | Billsby" 
        description="Simple, well-documented API and webhook management makes Billsby the most powerful, customizable, and easy-to-integrate recurring billing solution" 
        url="https://www.billsby.com/product/integrations/api-and-web-hooks"
      />

      <TwoColumn content={bannerContent}/>

      <div className="single-column-holder">
        <div className="container">
          <h2 className="heading">{singleContent.title}</h2>
          <p className="text">
            That's our simple guiding principle - everything you see in our pre-built tools, from our checkout and account management to our control panel, is built with the same APIs you can use to build your own solutions. The only thing you can't build for yourself is the credit card field - this is to help you maintain PCI compliance - but you can use our lightweight <Link to="/product/subscribers/checkout-and-payments">card tokenizer</Link> instead.
          </p>

          <div className="btn-wrapper">
            {
              singleContent.button.map((btn, i) => {
                return(
                  <a href={btn.url} 
                    rel="noopener noreferrer" 
                    target='_blank'
                    key={i} 
                    className={classNames('btn', 
                      {"btn-orange" : btn.buttonColor === "orange"},
                      {"btn-black" : btn.buttonColor === "black"},
                      {"btn-blue" : btn.buttonColor === "blue"})}>{btn.btnText}</a>
                )
              })
            }
          </div>
        </div>
      </div>
      
      <TwoColumn content={developersContent} />
     
      <div className="section-api-first">
        <div className="container container-flex">
          <div className="api-first-content">
            <h2 className="heading-cta">{apiFirst.title}</h2>
            {
              apiFirst.content.map((item, i) => (
                <div className="api-first-info" key={i}>
                  <p className="subheading-1 bold">{item.heading}</p>
                  <p className="subheading-1">{item.details}</p>
                </div>
              ))
            }
          </div>
          <div className="api-first-img">
            <img src={apiFirst.image} alt={apiFirst.imageName} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ApiAndWebHooks